import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Home = React.lazy(() => import("./pages/Home"));
const Earn = React.lazy(() => import("./pages/Earn"));

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home mobile={width <= 768} />} />
          <Route path="/shilltoearn" element={<Earn mobile={width <= 768} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
